<template>
	<edit-template @confirm="submitForm('data')" @cancel="onCancel" :cancelRemind="false" confirmText="提交" cancelText="取消">
		
		<el-form :model="data" :rules="rules" ref="data" label-width="100rem" class="demo-data" label-position="left">
			<h2>基础信息</h2>
			<el-row style="padding: 24rem 24rem 0;">
				<el-col :span="8">
					<el-form-item label="房间名称" prop="room_name">
						<el-input v-model="data.room_name" placeholder="请输入房间名称" @input="(v) => (data.room_name = v.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5/^@\-/(/)]/g, ''))"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="房间编号" prop="room_no">
						<el-input v-model="data.room_no" placeholder="请输入房间编号" @input="(v) => (data.room_no = v.replace(/[^a-zA-Z0-9/^@\-/(/)]/g, ''))"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="所属校区" prop="school_id">
						<el-select v-model="data.school_id" placeholder="请选择所属校区" :popper-append-to-body="false" @change="onSchool">
							<el-option v-for="item in schoolArr" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row style="padding: 0 24rem">
				<el-col :span="8">
					<el-form-item label="所属楼宇" prop="building_id">
						<el-select v-model="data.building_id" placeholder="请选择所属楼宇" :popper-append-to-body="false" :disabled="!data.school_id" @change="onBuilding">
							<el-option v-for="item in buildingidArr" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="房间类型" prop="room_type">
						<el-select v-model="data.room_type" placeholder="请选择房间类型" :popper-append-to-body="false">
							<el-option v-for="(item,key) of roomtypeArr" :label="item" :value="key"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="所属楼层" prop="floor">
						<el-select v-model="data.floor" placeholder="请选择所属楼层" :popper-append-to-body="false" :disabled="!data.building_id">
							<el-option v-for="item in floorArr" :label="item" :value="item"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row style="padding: 0 24rem">
				<el-col :span="8">
					<el-form-item label="容纳人数" prop="room_num">
						<el-input v-model="data.room_num" placeholder="请输入容纳人数" @input="(v) => (data.room_num = v.replace(/^0+(\d)|[^\d]+/g, ''))"></el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row style="padding: 0 24rem;margin-left: 12rem">
				<el-col :span="8">
					<el-form-item label="备注">
						<el-input type="textarea" placeholder="请输入" v-model="data.remarks" maxlength="300" show-word-limit="" style="margin-left: -12rem;">
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<h2>房间维护</h2>
			<el-row style="padding: 24rem">
				<el-col :span="8">
					<el-form-item label="房间状态" prop="room_status">
						<el-select v-model="data.room_status" placeholder="请选择房间状态" :popper-append-to-body="false">
							<el-option v-for="(item,key) of roomstatusArr" :label="item" :value="key"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="管理员" prop="account_name">
						<el-select v-model="data.account_name" :filterable="true" remote="" placeholder="请输入管理员" :remote-method="remoteMethod" :loading="loading" @change="onChange" default-first-option="" no-data-text="搜索无结果!" :focus="remoteMethod" :popper-append-to-body="false" @blur="blur()" ref="nameSel" :disabled="!data.school_id">
							<el-row style="text-align: center;padding: 12rem 24rem;">
								<el-col :span="12">教师姓名</el-col>
								<el-col :span="12">手机号</el-col>
							</el-row>
							<el-option v-for="item in JSON.parse(JSON.stringify(options))" :label="item.name" :value="{label:item.name,phone:item.phone}" :remove-tag="item.mobile">
								<el-row style="text-align: center">
									<el-col :span="12">{{ item.name }}</el-col>
									<el-col :span="12">{{ item.phone }}</el-col>
								</el-row>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item style="color: red;font-size: 14rem;margin: -12rem 0;height: 40rem">
						<div v-if="account_name_show">用户不在系统中，请确认！</div>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="管理员电话" prop="phone">
						<el-input v-model="data.phone" placeholder="请输入电话" @input="(v) => (data.phone = v.replace(/[^\d]/g, ''))" maxlength="11"></el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<h2>房间设施</h2>
			<el-row style="padding: 24rem 24rem 24rem 100rem">
				<el-col :span="2" style="opacity: 0;">0</el-col>
				<el-col :span="6">名称</el-col>
				<el-col :span="6">编号</el-col>
				<el-col :span="6">状态</el-col>
				<el-col :span="1" style="opacity: 0;">2</el-col>
			</el-row>
			<el-row style="padding: 0 24rem 24rem 100rem;display: flex;align-items: center" v-for="(item,index) in data.facilities">
				<el-col :span="2">设施{{ index + 1 }}</el-col>
				<el-col :span="6">
					<el-input v-model="data.facilities[index].name" placeholder="请输入名称" @input="(v) => (data.facilities[index].name = v.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5/^@\-/(/)]/g, ''))"></el-input>
				</el-col>
				<el-col :span="6">
					<el-input v-model="data.facilities[index].number" placeholder="请输入编号" @input="(v) => (data.facilities[index].number = v.replace(/[^a-zA-Z0-9]/g, ''))"></el-input>
				</el-col>
				<el-col :span="6">
					<el-select v-model="data.facilities[index].facilities_type" placeholder="请选择设备状态" :popper-append-to-body="false">
						<el-option v-for="(item,key) of facilitiestypeArr" :label="item" :value="key"></el-option>
					</el-select>
				</el-col>
				<el-col :span="1" style="font-size: 24rem;min-width: 62rem">
					<i class="el-icon-circle-close" @click="onDelete(index)"></i>
					<i class="el-icon-circle-plus-outline" style="margin-left: 12rem" @click="onAdd" v-show="index === data.facilities.length - 1"></i>
				</el-col>
			</el-row>
			<i class="el-icon-circle-plus-outline" style="margin-left: 12rem;font-size: 24rem" @click="onAdd" v-show="!data.facilities.length"></i>
		</el-form>
		
	
</edit-template>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  //import引入的组件需要注入到对象中才能使用",
	components: {},
	data() {
		return {
			data: {
				room_name: '',
				room_no: '',
				school_id: '',
				building_id: '',
				floor: '',
				room_type: '',
				room_num: '',
				room_status: '',
				account_name: '',
				phone: '',
				facilities: [{
					name: '',
					number: '',
					facilities_type: '',
				}],
			},
			rules: {
				room_name: [
					{ required: true, message: '请输入房间名称', trigger: 'change' },
					{ min: 1, max: 30, message: '不超过30个字符 ！', trigger: 'change' },
				],
				room_no: [
					{ required: true, message: '请输入房间编号', trigger: 'change' },
					{ min: 1, max: 10, message: '不超过10个字符 ！', trigger: 'change' },
				],
				school_id: [
					{ required: true, message: '请选择所属校区', trigger: 'change' },
				],
				building_id: [
					{ required: true, message: '请选择所属楼宇', trigger: 'change' },
				],
				room_type: [
					{ required: true, message: '请选择房间类型', trigger: 'change' },
				],
				floor: [
					{ required: true, message: '请选择所属楼层', trigger: 'change' },
				],
				room_num: [
					{ required: true, message: '请输入容纳人数', trigger: 'change' },
					{ pattern: /^([0-9][0-9]{0,2}|1000)$/, message: '容纳人数在0-1000', trigger: 'change' },
				],
				room_status: [
					{ required: true, message: '请选择房间状态', trigger: 'change' },
				],
				account_name: [
					{ required: true, message: '请选择管理员', trigger: 'change' },
				],
				phone: [
					{ required: true, message: '请输入管理员电话', trigger: 'change' },
					{ pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: '请输入正确的手机号码' },
				],
			},
			loading: false,
			account_name_show: false,
			options: [],
			schoolArr: [],
			roomtypeArr: [],
			roomstatusArr: [],
			facilitiestypeArr: [],
			buildingidArr: [],
			floorArr: []
		};
	},
	methods: {
		//	删除按钮
		onDelete(index) {
			this.$confirm(this.data.facilities[index].facilities_type !== '1' && this.data.facilities[index].facilities_type !== 1 ? '删除设备信息?' : '设备正在使用中,要删除吗?', {
				confirmButtonText: '删除',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				this.$message({
					type: 'success',
					message: '删除成功!',
				});
				this.data.facilities.splice(index, 1);
			}).catch(() => {});
		},

		//	新增按钮
		onAdd() {
			this.data.facilities.push({
				name: '',
				number: '',
				facilities_type: '',
			});
		},

		// 搜索教师
		remoteMethod(query) {
			if (query !== '') {
				this.loading = true;
				this.$_axios2.get('api/room/room/work?name=' + query + '&school_id=' + this.data.school_id).then(res => {
					this.loading = false;
					this.options = res.data.data;
				});
			}
		},

		//	选择教师
		onChange(value) {
			this.data.phone = value.phone;
			this.data.account_name = value.label;
			this.account_name_show = false;
		},

		//  选择校区
		onSchool(val) {
			this.data.building_id = '';
			this.data.floor = '';
			this.data.phone = '';
			this.options = [];
			this.data.account_name = '';
			this.account_name_show = false;
			this.$_axios2.get('api/room/room/building', { params: { school_id: val } }).then(res => {
				this.buildingidArr = res.data.data;
			});
		},

		//  选择楼宇
		onBuilding(val) {
			this.data.floor = '';
			this.floorArr = [];
			let result = this.buildingidArr.find(r => {
				return r.id === val;
			});
			for (let i = result.floor_min; i <= result.floor_max; i++) {
				if (i !== 0) {
					this.floorArr.push(i);
				}
			}
		},

		//	提交
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					let data = JSON.parse(JSON.stringify(this.data));
					if (data.facilities.length === 1) {
						if (data.facilities[0].name === '' && data.facilities[0].number === '' && data.facilities[0].facilities_type === '') {
							data.facilities = [];
						}
					}
					this.$_axios2.post('api/room/room/add', data).then(res => {
						if (res.data.status === 0) {
							this.$store.commit('setPage', 1);
							this.$message.success('新增成功');
							this.$router.back();
						} else {
							this.$message.error(res.data.msg);
						}
					});
				}
			});
		},

		//	取消
		onCancel() {
			this.$confirm('是否取消新增', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				this.$router.back();
			}).catch(() => {});
		},

		blur() {
			this.data.account_name = this.$refs.nameSel.query;
			if (this.data.account_name === '') {
				this.account_name_show = false;
			} else {
				if (this.options.length === 0) {
					this.account_name_show = true;
				} else {
					for (let i = 0; i < this.options.length; i++) {
						if (this.options[i].name === this.data.account_name) {
							this.account_name_show = false;
							return;
						} else {
							this.account_name_show = true;
							return;
						}
					}
				}
			}
		}
	},

	created() {
		//  校区数据获取
		this.$_axios2.get('api/room/room/school').then(res => {
			this.schoolArr = res.data.data;
		});
		//  房间状态，设施状态，房间类型
		this.$_axios2.get('api/room/room/type').then(res => {
			this.roomtypeArr = res.data.data.room_type;
			this.roomstatusArr = res.data.data.room_status;
			this.facilitiestypeArr = res.data.data.facilities_type;
		});
	}
};

</script>

<style scoped lang="scss">
::v-deep .el-textarea__inner {
	height: 100rem;
}
</style>
